.price-unit-field .syt-antd-input-wrapper-default input, .price-unit-field  .syt-antd-input-affix-wrapper {
    margin: 0 !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.price-unit-field .syt-antd-select-selection-search input{
    --tw-ring-color: transparent;
}

.price-unit-field .syt-antd-select-open .syt-antd-select-arrow>svg {
    transform: rotate(180deg);
}

.price-unit-field .syt-antd-select-disabled .syt-antd-select-arrow>svg {
    fill: #696F88;
}

.price-unit-field  .syt-antd-select-selector {
    border-color: none;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
}